import axios from 'axios';
import { API_URL } from '../../store/api/api.const';
import { GetCosts } from './OrderSummary.types';
import { ProductType } from '../../store/cart/cart.dto';

type Costs = {
  subtotal: number;
  shipping: number;
  discount: number;
  total: number;
  rural_surcharge: number | undefined;
  errors: Array<string> | null;
  applied_discounts: Array<{ code: string }> | null;
};

export const getCosts = async ({ products, discounts, delivery_address }: GetCosts): Promise<Costs | false> => {
  try {
    const res = await axios.post(`${API_URL}/account/costs/`, {
      products,
      discounts,
      delivery_address,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    alert('Something went wrong. Please try again later');
    return false;
  }
};

export const nearestDate = (dates: Array<string>): string => {
  const now = new Date();

  let closest: string | number = Infinity;

  dates.forEach(d => {
    const date = new Date(d);

    if (date >= now && (date < new Date(closest) || Number(date) < closest)) {
      closest = d;
    }
  });

  if (typeof closest === 'number') {
    return '';
  }

  return closest;
};

export const formattedProduct = (products: ProductType[]) =>
  products.map(({ product_variant_id, frequency_id, quantity, start_date, exclusions }) => ({
    product_variant_id,
    frequency_id,
    quantity: Number(quantity),
    start_date,
    exclusions,
  }));
