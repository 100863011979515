import { Button } from '../Button';
import {
  Container,
  ContainerInput,
  DiscountInput,
  DiscountItem,
  DiscountItemBtn,
  DiscountItemCode,
  DiscountItemWrap,
  DiscountList,
  Error,
} from './Discount.parts';
import React, { useState } from 'react';
import { formattedProduct, getCosts } from '../OrderSummary/OrderSummary.helpers';
import { useDispatch, useSelector } from 'react-redux';
import { selectorCart } from '../../store/cart/cart.selectors';
import { addCosts, addDiscount, removeDiscount } from '../../store/cart/cart.reducers';

export const Discount = () => {
  const { products, address, discounts } = useSelector(selectorCart);
  const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setValue(target.value.trim());

    if (target.value.trim().length > 3) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleClick = () => {
    const calculateCosts = async () => {
      setLoading(true);

      const res = await getCosts({
        products: formattedProduct(products),
        delivery_address: {
          address: address.address,
          postcode: address.postcode,
          address_id: address.nzp_address_id,
          is_rural_delivery: address.is_rural_delivery,
        },
        discounts: discounts.find(item => item.code === value) ? [...discounts] : [...discounts, { code: value }],
      });

      if (res) {
        const appliedDiscounts = res.applied_discounts?.find(item => item.code === value);

        if (appliedDiscounts) {
          dispatch(addDiscount(appliedDiscounts));
          setError('');
        }

        const errors = res.errors?.find(err => err.includes(value));

        if (errors) {
          setError(errors);
        }

        setValue('');
        setDisabled(true);
        dispatch(
          addCosts({
            shipping: res.shipping,
            discount: res.discount,
            subtotal: res.subtotal,
            total: res.total,
            rural: res?.rural_surcharge || 0,
          }),
        );
      }
      setLoading(false);
    };

    void calculateCosts();
  };

  const handleDelete = (discount: string) => {
    const deleteDiscount = async () => {
      setLoading(true);

      const res = await getCosts({
        products: formattedProduct(products),
        delivery_address: {
          address: address.address,
          postcode: address.postcode,
          address_id: address.nzp_address_id,
          is_rural_delivery: address.is_rural_delivery,
        },
        discounts: discounts.filter(item => item.code !== discount),
      });

      if (res) {
        dispatch(removeDiscount(discount));
        dispatch(
          addCosts({
            shipping: res.shipping,
            discount: res.discount,
            subtotal: res.subtotal,
            total: res.total,
            rural: res?.rural_surcharge || 0,
          }),
        );
      }

      setLoading(false);
    };

    void deleteDiscount();
  };

  return (
    <Container>
      <ContainerInput>
        <DiscountInput onInput={event => handleChange(event)} value={value} placeholder={'Enter discount code'} />
        <Button onClick={() => handleClick()} disabled={disabled || loading} loading={loading}>
          Apply
        </Button>
        {error && <Error>{error}</Error>}
      </ContainerInput>

      <DiscountList>
        {discounts.map(item => (
          <DiscountItem key={item.code}>
            <DiscountItemWrap>
              <DiscountItemCode>{item.name}</DiscountItemCode>
            </DiscountItemWrap>

            <DiscountItemBtn onClick={() => handleDelete(item.code)} />
          </DiscountItem>
        ))}
      </DiscountList>
    </Container>
  );
};
