import { useEffect } from 'react';
import {
  Container,
  ProductItem,
  ProductItemImg,
  ProductItemPrice,
  ProductItemText,
  ProductList,
  StyledTitle,
  SummaryPrice,
  SummaryPriceItem,
  SummaryPriceItemTitle,
  SummaryPriceItemValue,
  Text,
} from './OrderSummary.parts';
import { Discount } from '../Discount';
import { useDispatch, useSelector } from 'react-redux';
import { selectorCart } from '../../store/cart/cart.selectors';
import { formattedProduct, getCosts, nearestDate } from './OrderSummary.helpers';
import { addCosts } from '../../store/cart/cart.reducers';
import { dateFormat } from '../../helpers/dateFormat';

export const OrderSummary = () => {
  const { products, address, discounts, costs } = useSelector(selectorCart);
  const dispatch = useDispatch();

  const dateArr = products.map(item => item.start_date);
  const firstDate = nearestDate(dateArr);

  useEffect(() => {
    const calculateCosts = async () => {
      const res = await getCosts({
        products: formattedProduct(products),
        discounts,
        delivery_address: {
          address: address.address,
          postcode: address.postcode,
          address_id: address.nzp_address_id,
          is_rural_delivery: address.is_rural_delivery,
        }
      });

      if (res) {
        dispatch(addCosts({ ...res, rural: res.rural_surcharge || 0 }));
      }
    };

    void calculateCosts();
  }, []);

  return (
    <Container>
      <StyledTitle>Order Summary</StyledTitle>
      <Text>
        <strong>First delivery date:</strong> {dateFormat(firstDate)}
      </Text>
      <ProductList>
        {products.map(product => (
          <ProductItem key={product.product_variant_id}>
            <ProductItemImg>
              <img src={product.img} alt="" />
            </ProductItemImg>
            <ProductItemText>
              {product.size} {product.title} X {product.quantity} <br />
              {product.frequency}
            </ProductItemText>
            <ProductItemPrice>${product.price.toFixed(2)}</ProductItemPrice>
          </ProductItem>
        ))}
      </ProductList>
      <Discount />
      <SummaryPrice>
        <SummaryPriceItem>
          <SummaryPriceItemTitle>Subtotal</SummaryPriceItemTitle>
          <SummaryPriceItemValue>${costs.subtotal.toFixed(2)}</SummaryPriceItemValue>
        </SummaryPriceItem>
        <SummaryPriceItem>
          <SummaryPriceItemTitle>Shipping</SummaryPriceItemTitle>
          <SummaryPriceItemValue>${costs.shipping.toFixed(2)}</SummaryPriceItemValue>
        </SummaryPriceItem>
        {!!costs.rural && (
          <SummaryPriceItem>
            <SummaryPriceItemTitle>Rural Delivery Fee:</SummaryPriceItemTitle>
            <SummaryPriceItemValue>${costs.rural.toFixed(2)}</SummaryPriceItemValue>
          </SummaryPriceItem>
        )}
        <SummaryPriceItem>
          <SummaryPriceItemTitle>Discount</SummaryPriceItemTitle>
          <SummaryPriceItemValue>${costs.discount.toFixed(2)}</SummaryPriceItemValue>
        </SummaryPriceItem>
        <SummaryPriceItem>
          <SummaryPriceItemTitle>
            <strong>Total</strong>
          </SummaryPriceItemTitle>
          <SummaryPriceItemValue>
            <strong>${costs.total.toFixed(2)}</strong>
          </SummaryPriceItemValue>
        </SummaryPriceItem>
      </SummaryPrice>
    </Container>
  );
};
